.cookies {
  position: absolute;
  z-index: 998;
  width: 100%;
  display: none;
  top: 0;
  right: 0;
  left: 0;
  height: 35px;
  text-align: center;
  line-height: 25px;
  background: linear-gradient(145deg, $theme-color-2 0%, $theme-color-3 100%);
  color: white;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 100;
  padding-top: 5px;
  padding-bottom: 5px;
  
  .message {
	
	a {
		color: $color-3;
	  }
    white-space: nowrap;
	
	z-index:998;
    @media (max-width: 767px){
      display: none;
    }
  }
  .mobile {
	display: none;
	
    @media (max-width: 767px){
      display: inline-block;
	}
	a {
		color: $color-3;
	  }
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
.close-cb {
  border: none;
  color: white;
  background: darken($theme-color, 10%);
  position: absolute;
  display: inline-block;
  margin-left:0.5em;
  top: 5px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: inset 0 0 3px 0 rgba(0,0,0,.2);
  line-height: 25px;
  height: 25px;
  width: 30px;
  font-size: 16px;
  font-weight: bold;
  z-index:999;
  &:hover {
    background: darken($theme-color, 10%);
  }
  
}
.checkbox-cb {
  display: none;
  &:checked + .cookie-bar {
    transform: translateY(-50px);
  }
}


/**
 * Typography
 */

* {
	box-sizing: border-box;
}

.breadcrumb{
	background: linear-gradient(145deg, $theme-color-2 0%, $theme-color-3 100%);
	&::before {
		background-image: url("/assets/images/bg/bg-breadcrumb.jpg");
	}
}

img {
	max-width: 100%;
}
html {
	overflow: hidden;
	overflow-y: auto;
}
body {
	overflow: hidden;
	font-size: $body-font-size;
	line-height: $body-line-height;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $body-font;
	color: $body-color;
	font-weight: 400;
}

a {
	transition: all 0.4s ease-in-out 0s;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
	margin: 0;
	margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $heading-font;
	line-height: 1.4074;
	color: $heading-color;
}
h1,
.h1 {
	font-size: $h1;
}
h2,
.h2 {
	font-size: $h2;
}
h3,
.h3 {
	font-size: $h3;
}
h4,
.h4 {
	font-size: $h4;
}
h5,
.h5 {
	font-size: $h5;
}
h6,
.h6 {
	font-size: $h6;
}

@media #{$md-layout} {
	h1,
	.h1 {
		font-size: $h1 - 4;
	}
	
	h2,
	.h2 {
		font-size: $h2 - 2;
	}
	h3,
	.h3 {
		font-size: $h3 - 2;
	}
}

@media #{$sm-layout} {
	h1,
	.h1 {
		font-size: $h1 - 6;
	}
	
	h2,
	.h2 {
		font-size:$h2 - 4;
	}
	h3,
	.h3 {
		font-size: $h3 - 2;
	}
}

@media #{$large-mobile} {
	h1,
	.h1 {
		font-size: $h1 - 8;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	font-weight: 700;
}
h4,
.h4,
h5,
.h5 {
	font-weight: 600;
}
h6,
.h6 {
	font-weight: 500;
}
a:hover,
a:focus,
a:active {
	text-decoration: none;
	outline: none;
}
a:visited {
	color: inherit;
}
input,
button,
select,
textarea {
	background: transparent;
	border: 1px solid $border-color;
	transition: all 0.4s ease-out 0s;
	color: $body-color;
	&:focus,
	&:active {
		outline: none;
		border-color: $theme-color;
	}
}
input,
select,
textarea {
	width: 100%;
	font-size: 14px;
}
input,
select {
	height: 40px;
	padding: 0 15px;
}